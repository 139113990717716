import React, { useEffect, useState } from "react";

import useGetAddresses from "hooks/useGetAddresses";
import { Input } from "@chakra-ui/react";

export default ({
  setPostCode,
  setLat,
  setLong,
  setPostCodeInvalid,
  value,
}) => {
  const { lat, long, isValid, isLoading, setPostcodeQuery } = useGetAddresses();

  useEffect(() => {
    setLat(lat);
    setLong(long);
    setPostCodeInvalid(!isValid);
  }, [lat, long, isValid, isLoading, setLat, setLong, setPostCodeInvalid]);

  return (
    <>
      <Input
        placeholder="BS5 3TS"
        value={value}
        onChange={(e) => {
          setPostCode(e.target.value.toUpperCase());
          setPostcodeQuery(e.target.value.toUpperCase());
        }}
        onBlur={() => {
          setPostcodeQuery(value);
        }}
      ></Input>
    </>
  );
};
