import { GetAddresses } from "api/openApi.ts";

const { useState } = require("react");

const useGetAddresses = () => {
  const [lat, setLat] = useState();
  const [long, setLong] = useState();
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const setPostcodeQuery = async (postcode) => {
    setIsLoading(true);
    const postcodeDetails = await GetAddresses(postcode);
    if (postcodeDetails) {
      setLat(postcodeDetails.lat);
      setLong(postcodeDetails.long);
      setIsValid(true);
    } else {
      setLat(undefined);
      setLong(undefined);
      setIsValid(false);
    }
    setIsLoading(false);
  };

  return { lat, long, isLoading, isValid, setPostcodeQuery };
};

export default useGetAddresses;
