import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";

const Card = tw.div`tw-mt-12 tw-w-full sm:tw-w-1/2 lg:tw-w-1/3 tw-flex tw-flex-col tw-items-center`;
const CardImage = styled.div`
  ${(props) => css`
    background-image: url("${props.imageSrc}");
  `}
  ${tw`tw-w-64 tw-h-64 tw-bg-cover tw-bg-center tw-rounded`}
`;
const CardContent = styled.div`
  ${tw`tw-flex tw-flex-col tw-items-center tw-mt-6`}
  .position {
    ${tw`tw-uppercase tw-font-bold tw-tracking-widest tw-text-xs tw-text-primary-500`}
  }
  .name {
    ${tw`tw-mt-1 tw-text-xl tw-font-medium tw-text-gray-900`}
  }
`;

const coaches = [
  {
    imageSrc: "/MariaPowell.jpg",
    position1: "BTF Level 2 Triathlon Coach",
    name: "Maria Powell",
  },
  {
    imageSrc: "/AndyParsons.jpg",
    position1: "BTF Level 3 Triathlon Coach",
    position2: "England Athletics - Running Coach",
    name: "Andy Parsons",
  },
  {
    imageSrc: "/HannahBizoumis.jpg",
    position1: "Personal Trainer Level 3",
    position2: "Ironman Triathlon Coach",
    name: "Hannah Bizoumis",
  },
  {
    imageSrc: "/HansPeterKiekuth.jpeg",
    position1: "BTF Level 2 Triathlon Coach",
    name: "Hans-Peter Kiekuth",
  },
  {
    imageSrc: "/JennyCampbellRitchie.jpg",
    position1: "Ride Leader",
    name: "Jenny Campbell-Ritchie",
  },
  {
    imageSrc: "/PatrickNewman.jpeg",
    position1: "BTF Level 2 Triathlon Coach",
    position2: "ASA Level 2 Swim Teacher",
    name: "Patrick Newman",
  },
  {
    imageSrc: "/EmmaAllum.jpg",
    position1: "BTF Level 1 Triathlon Coach",
    name: "Emma Allum",
  },
  {
    imageSrc: "/JonWaring.jpeg",
    position1: "BTF Level 2 Triathlon Coach",
    name: "Jon Waring",
  },
  {
    imageSrc: "/KateRoss.jpg",
    position1: "BTF Level 2 Triathlon Coach",
    position2: "BSc Sports, Fitness and Coaching",
    name: "Kate Ross",
  },
  {
    imageSrc: "/BenHolding.jpeg",
    position1: "BTF Level 2 Triathlon Coach",
    position2: "Previously an ASA Level 3 Swim Teacher",
    name: "Ben Holding",
  },
  {
    imageSrc: "/BruceWillerton.jpg",
    position1: "BTF Level 2 Triathlon Coach",
    name: "Bruce Willerton",
  },
  {
    imageSrc: "/ChrisHornung.jpg",
    position1: "BTF Level 2 Triathlon Coach",
    name: "Chris Hornung",
  },
  {
    imageSrc: "/JamieHinton.jpg",
    position1: "BTF Level 1 Triathlon Coach",
    name: "Jamie Hinton",
  },
];

export default function () {
  return coaches.map((card, index) => {
    return (
      <Card key={index}>
        <CardImage imageSrc={card.imageSrc} />
        <CardContent>
          <span className="position">{card.position1}</span>
          <span className="position">{card.position2}</span>
          <span className="name">{card.name}</span>
        </CardContent>
      </Card>
    );
  });
}
