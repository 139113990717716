import React, { useEffect } from "react";

import useMember from "./hooks/useMember.js";
import tw from "twin.macro";
import { Button, Spinner } from "@chakra-ui/react";
import Header from "components/header/Header.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { ContentWithPaddingXl } from "components/misc/Layouts.js";
import { useSearchParams } from "react-router-dom";

const Heading = tw.h1`tw-font-bold tw-text-xl tw-pb-5`;
const HeadingCentre = tw.h1`tw-m-auto tw-w-fit tw-font-bold tw-text-xl tw-pb-5`;
const Title = tw.p``;
const TitleCentre = tw.p`tw-m-auto tw-w-fit`;
const SpinnerContainer = tw.div`tw-m-auto tw-w-fit tw-pt-8`;

export default () => {
  const { setMembershipNumber, isMember, isAdult, isLoading } = useMember();
  const [searchParams] = useSearchParams();
  const membershipNumber = searchParams.get("membershipNumber");

  useEffect(() => {
    console.log(membershipNumber);
    setMembershipNumber(membershipNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMember && !isLoading) {
      if (isAdult) {
        window.location.href =
          process.env.REACT_APP_SENIORS_PAYMENT_LINK +
          "?client_reference_id=" +
          membershipNumber;
      } else {
        window.location.href =
          process.env.REACT_APP_JUNIORS_PAYMENT_LINK +
          "?client_reference_id=" +
          membershipNumber;
      }
    }
  }, [isLoading, isMember, isAdult, membershipNumber]);

  return (
    <>
      {isLoading && (
        <AnimationRevealPage>
          <ContentWithPaddingXl>
            <HeadingCentre>Generating your payment link</HeadingCentre>
            <TitleCentre>
              Once generated we'll redirect you to Stripe to make your payment
            </TitleCentre>
            <SpinnerContainer>
              <Spinner
                color="red.500"
                css={{
                  "--spinner-track-color": "colors.gray.200",
                  width: "50px",
                  height: "50px",
                }}
              />
            </SpinnerContainer>
          </ContentWithPaddingXl>
        </AnimationRevealPage>
      )}
      {!isLoading && !isMember && (
        <AnimationRevealPage>
          <Header />
          <ContentWithPaddingXl>
            <Heading>Sorry we can't generate your payment link</Heading>
            <Title>
              It seems you're too late to pay for membership renewal, and your
              account has been deleted. Please sign up again as a new member, or
              contact us if you don't think this is correct. (All deleted
              members will have received a goodbye email).
            </Title>
            <Button
              marginRight={2}
              marginTop={8}
              as="a"
              href="/contact-us"
              variant={"outline"}
              colorScheme={"red"}
            >
              CONTACT US
            </Button>
            <Button marginTop={8} as="a" href="/join" colorScheme={"red"}>
              SIGN UP
            </Button>
          </ContentWithPaddingXl>
        </AnimationRevealPage>
      )}
    </>
  );
};
