import { GetIsMember } from "api/openApi.ts";
import { useState } from "react";

const useMember = () => {
  const [isMember, setIsMember] = useState();
  const [isAdult, setIsAdult] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, SetError] = useState();

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const setMembershipNumber = async (membershipNumber) => {
    setIsLoading(true);
    try {
      if (membershipNumber) {
        await delay(5000);
        const isMemberResponse = await GetIsMember({
          membershipNumber: membershipNumber,
        });
        setIsMember(isMemberResponse.isMember);
        setIsAdult(isMemberResponse.isAdult);
      }
    } catch (e) {
      if (e instanceof Error) {
        SetError(e);
      }
    }
    setIsLoading(false);
  };

  return {
    setMembershipNumber,
    isMember,
    isAdult,
    isLoading,
    error,
  };
};

export default useMember;
