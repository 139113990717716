//KEEP so you can add tw={} to components
import tw from "twin.macro";

import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  ModalBody,
  Textarea,
  FormErrorMessage,
} from "@chakra-ui/react";

export default ({
  sessionDetails,
  newSession,
  setNewSession,
  shouldCheckInvalidity,
  sessionNameValid,
  sessionLocationValid,
  sessionTimeValid,
  sessionPriceValid,
  sessionDescriptionValid,
}) => {
  return (
    <ModalBody>
      <FormControl
        tw="tw-mb-4"
        isInvalid={shouldCheckInvalidity && sessionNameValid}
      >
        <FormLabel>Name</FormLabel>
        <Input
          id={"activity-name"}
          defaultValue={sessionDetails.title}
          onChange={(e) => {
            const newSessionUpdate = { ...newSession };
            newSessionUpdate.title = e.target.value;
            setNewSession(newSessionUpdate);
          }}
        />
        {!shouldCheckInvalidity ||
        (shouldCheckInvalidity && !sessionNameValid) ? (
          <FormHelperText>
            Three words max to accommodate for mobile screens
          </FormHelperText>
        ) : (
          <FormErrorMessage>A session name is required</FormErrorMessage>
        )}
      </FormControl>

      <FormControl tw="tw-mb-4">
        <FormLabel>Tag</FormLabel>
        <Input
          tw={"tw-uppercase"}
          id={"activity-tag"}
          defaultValue={sessionDetails.headline}
          onChange={(e) => {
            const newSessionUpdate = { ...newSession };
            newSessionUpdate.headline = e.target.value;
            setNewSession(newSessionUpdate);
          }}
        />
        <FormHelperText>optional - keep very short</FormHelperText>
      </FormControl>

      <FormControl
        tw="tw-mb-4"
        isInvalid={shouldCheckInvalidity && sessionLocationValid}
      >
        <FormLabel>Location</FormLabel>
        <Input
          id={"activity-location"}
          defaultValue={sessionDetails.location}
          onChange={(e) => {
            const newSessionUpdate = { ...newSession };
            newSessionUpdate.location = e.target.value;
            setNewSession(newSessionUpdate);
          }}
        />
        {!shouldCheckInvalidity ||
        (shouldCheckInvalidity && !sessionLocationValid) ? (
          <FormHelperText>First line and postcode only</FormHelperText>
        ) : (
          <FormErrorMessage>A location is required</FormErrorMessage>
        )}
      </FormControl>

      <FormControl
        tw="tw-mb-4"
        isInvalid={shouldCheckInvalidity && sessionTimeValid}
      >
        <FormLabel>Time</FormLabel>
        <Input
          id={"activity-time"}
          defaultValue={sessionDetails.time}
          onChange={(e) => {
            const newSessionUpdate = { ...newSession };
            newSessionUpdate.time = e.target.value;
            setNewSession(newSessionUpdate);
          }}
        />
        {!shouldCheckInvalidity ||
        (shouldCheckInvalidity && !sessionTimeValid) ? (
          <FormHelperText>24 hour clock format</FormHelperText>
        ) : (
          <FormErrorMessage>Must be in the format: 18:30</FormErrorMessage>
        )}
      </FormControl>

      <FormControl
        tw="tw-mb-4"
        isInvalid={shouldCheckInvalidity && sessionPriceValid}
      >
        <FormLabel>Price</FormLabel>
        <Input
          id={"activity-price"}
          defaultValue={sessionDetails.price}
          onChange={(e) => {
            const newSessionUpdate = { ...newSession };
            newSessionUpdate.price = e.target.value;
            setNewSession(newSessionUpdate);
          }}
        />
        {!shouldCheckInvalidity ||
        (shouldCheckInvalidity && !sessionPriceValid) ? (
          <FormHelperText>£X or FREE</FormHelperText>
        ) : (
          <FormErrorMessage>
            Must begin with £ or be FREE, for example: £6 or FREE
          </FormErrorMessage>
        )}
      </FormControl>

      <FormControl
        tw="tw-mb-4"
        isInvalid={shouldCheckInvalidity && sessionDescriptionValid}
      >
        <FormLabel>Description</FormLabel>
        <Textarea
          id={"activity-description"}
          defaultValue={sessionDetails.description}
          onChange={(e) => {
            const newSessionUpdate = { ...newSession };
            newSessionUpdate.description = e.target.value;
            setNewSession(newSessionUpdate);
          }}
        />
        {!shouldCheckInvalidity ||
        (shouldCheckInvalidity && !sessionDescriptionValid) ? (
          <FormHelperText>
            Try to keep concise - ideally no longer than 4 lines
          </FormHelperText>
        ) : (
          <FormErrorMessage>A session description is required</FormErrorMessage>
        )}
      </FormControl>
    </ModalBody>
  );
};
