import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import illustration from "images/IMG_9341.JPG";
import logo from "images/BADtri_logo_black.svg";
import { SignUp } from "api/openApi.ts";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";
import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Select,
  useToast,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { IsAdult } from "api/jwt.js";
import AddressLookUp from "components/addressLookUp/AddressLookUp";

const Container = tw.div`tw-relative tw-bg-gray-200 tw-bg-primary-800 -tw-mx-8 tw-px-8 tw-py-5`;
const Content = tw.div`tw-max-w-screen-xl tw-mx-auto tw-my-8 tw-bg-white tw-text-gray-900 tw-shadow sm:tw-rounded-lg tw-flex tw-justify-center tw-flex-1`;
const MainContainer = tw.div`lg:tw-w-1/2 xl:tw-w-5/12 tw-p-6 sm:tw-p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`tw-h-16 tw-mx-auto`;
const MainContent = tw.div`tw-mt-12 tw-flex tw-flex-col tw-items-center`;
const Heading = tw.h1`tw-text-2xl xl:tw-text-3xl tw-font-extrabold`;
const FormContainer = tw.div`tw-w-full tw-flex-1 tw-mt-6`;
const HaveAnAccountButton = tw.p`tw-mt-8 tw-text-sm tw-text-gray-600 tw-text-center`;
const HaveAnAccountText = tw.a`tw-border-b tw-border-gray-500 tw-border-dotted`;

const Form = tw.form`tw-mx-auto tw-max-w-xs`;
const ChakraFormControl = tw(FormControl)`tw-mt-5`;

const IllustrationContainer = tw.div`sm:tw-rounded-r-lg tw-flex-1 tw-bg-purple-100 tw-text-center tw-overflow-hidden tw-hidden lg:tw-flex tw-justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`tw-w-full tw-bg-cover tw-bg-center tw-bg-no-repeat`}
`;

export default ({
  logoLinkUrl = "/",
  illustrationImageSrc = illustration,
  SubmitButtonIcon = SignUpIcon,
  signInUrl = "login",
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [step, setStep] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [firstNameInvalid, setFirstNameInvalid] = useState(false);
  const [familyName, setFamilyName] = useState("");
  const [familyNameInvalid, setFamilyNameInvalid] = useState(false);
  const [parentFirstName, setParentFirstName] = useState("");
  const [parentFirstNameInvalid, setParentFirstNameInvalid] = useState(false);
  const [parentFamilyName, setParentFamilyName] = useState("");
  const [parentFamilyNameInvalid, setParentFamilyNameInvalid] = useState(false);
  const [parentEmailAddress, setParentEmailAddress] = useState("");
  const [parentEmailAddressInvalid, setParentEmailAddressInvalid] =
    useState(false);
  const [parentMobileNumber, setParentMobileNumber] = useState("");
  const [parentMobileNumberInvalid, setParentMobileNumberInvalid] =
    useState(false);
  const [gender, setGender] = useState("");
  const [genderInvalid, setGenderInvalid] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberInvalid, setPhoneNumberInvalid] = useState(false);
  const [emailAddressInvalid, setEmailAddressInvalid] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [dateOfBirthInvalid, setDateOfBirthInvalid] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [postCodeInvalid, setPostCodeInvalid] = useState(false);
  const [postCode, setPostCode] = useState("");
  const [agreement, setAgreement] = useState(false);
  const [agreementInvalid, setAgreementInvalid] = useState(false);
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const toast = useToast();

  const previousStep = function () {
    if (IsAdult(dateOfBirth)) {
      setStep(1);
    } else {
      setStep(step - 1);
    }
  };

  const nextStep = function () {
    if (step === 1) {
      validateStep1();
    }
    if (step === 2) {
      validateStep2();
    }
  };

  const validateStep1 = function () {
    if (firstName === "") {
      setFirstNameInvalid(true);
      return;
    }

    if (familyName === "") {
      setFamilyNameInvalid(true);
      return;
    }

    if (dateOfBirth === "") {
      setDateOfBirthInvalid(true);
      return;
    } else if (
      Number(dateOfBirth.split("-")[0]) < new Date().getFullYear() - 100 ||
      Number(dateOfBirth.split("-")[0]) > new Date().getFullYear() - 3
    ) {
      setDateOfBirthInvalid(true);
      return;
    }

    if (
      emailAddress === "" ||
      !emailAddress.includes("@") ||
      !emailAddress.split("@")[1].includes(".") ||
      !(emailAddress.split("@")[1].split(".")[1].length > 1)
    ) {
      setEmailAddressInvalid(true);
      return;
    }

    if (password.length < 8) {
      setPasswordInvalid(true);
      return;
    }
    if (IsAdult(dateOfBirth)) {
      setStep(3);
    } else {
      setStep(2);
    }
  };

  const validateStep2 = function () {
    if (parentFirstName === "") {
      setParentFirstNameInvalid(true);
      return;
    }

    if (parentFamilyName === "") {
      setParentFamilyNameInvalid(true);
      return;
    }

    if (
      parentEmailAddress === "" ||
      !parentEmailAddress.includes("@") ||
      !parentEmailAddress.split("@")[1].includes(".") ||
      !(parentEmailAddress.split("@")[1].split(".")[1].length > 1)
    ) {
      setParentEmailAddressInvalid(true);
      return;
    }

    if (parentMobileNumber.length !== 11 || parentMobileNumber.includes(".")) {
      setParentMobileNumberInvalid(true);
      return;
    }

    setStep(3);
  };

  const signup = async () => {
    if (gender === "") {
      setGenderInvalid(true);
      return;
    }

    if (phoneNumber.length !== 11 || phoneNumber.includes(".")) {
      setPhoneNumberInvalid(true);
      return;
    }

    if (postCode === "") {
      setPostCodeInvalid(true);
      return;
    }

    if (agreement === false) {
      setAgreementInvalid(true);
      return;
    }

    const signUpRequest = {
      firstName: firstName,
      familyName: familyName,
      emailAddress: emailAddress,
      password: password,
      dateOfBirth: dateOfBirth,
      phoneNumber: phoneNumber,
      gender: gender,
      postCode: postCode,
      lat: lat,
      long: long,
      parentFirstName: parentFirstName,
      parentFamilyName: parentFamilyName,
      parentEmailAddress: parentEmailAddress,
      parentPhoneNumber: parentMobileNumber,
    };
    setButtonDisabled(true);

    //TODO - check values are valid

    SignUp(signUpRequest)
      .then((response) => {
        document.location.href =
          "/verify?membershipNumber=" + response.membershipNumber;
        toast({
          title: "Sign up successful - check your emails",
          status: "success",
          isClosable: true,
        });
      })
      .catch((error) => {
        setButtonDisabled(false);
        toast({
          title: "Something went wrong - try again!",
          status: "error",
          isClosable: true,
        });
      });
  };

  return (
    <Container>
      <Content>
        <MainContainer>
          <LogoLink href={logoLinkUrl}>
            <LogoImage src={logo} />
          </LogoLink>
          <MainContent>
            <Heading>Sign Up</Heading>
            <FormContainer>
              <Form>
                {step === 1 && (
                  <>
                    <ChakraFormControl isInvalid={firstNameInvalid}>
                      <FormLabel>First Name</FormLabel>
                      <Input
                        size="lg"
                        type="name"
                        value={firstName}
                        placeholder="First Name"
                        onChange={(e) => {
                          setFirstNameInvalid(e.target.value === "");
                          setFirstName(e.target.value);
                        }}
                      />
                      <FormErrorMessage>
                        First name is required
                      </FormErrorMessage>
                    </ChakraFormControl>

                    <ChakraFormControl isInvalid={familyNameInvalid}>
                      <FormLabel>Surname</FormLabel>
                      <Input
                        size="lg"
                        type="name"
                        value={familyName}
                        placeholder="Surname"
                        onChange={(e) => {
                          setFamilyNameInvalid(e.target.value === "");
                          setFamilyName(e.target.value);
                        }}
                      />
                      <FormErrorMessage>Surname is required</FormErrorMessage>
                    </ChakraFormControl>

                    <ChakraFormControl isInvalid={dateOfBirthInvalid}>
                      <FormLabel>Date of Birth</FormLabel>
                      <Input
                        size="lg"
                        autoComplete="bday"
                        type="date"
                        value={dateOfBirth}
                        placeholder="Date of Birth"
                        onChange={(e) => {
                          if (
                            Number(e.target.value.split("-")[0]) > 1920 &&
                            dateOfBirthInvalid
                          ) {
                            setDateOfBirthInvalid(false);
                            return;
                          }
                          setDateOfBirth(e.target.value);
                        }}
                      />
                      <FormErrorMessage>
                        A valid date of birth is required
                      </FormErrorMessage>
                    </ChakraFormControl>

                    <ChakraFormControl isInvalid={emailAddressInvalid}>
                      <FormLabel>Email Address</FormLabel>
                      <Input
                        size="lg"
                        value={emailAddress}
                        autoComplete="email"
                        type="email"
                        placeholder="Email Address"
                        onChange={(e) => {
                          if (
                            e.target.value.includes("@") &&
                            e.target.value.split("@")[1].includes(".") &&
                            e.target.value.split("@")[1].split(".")[1].length >
                              1 &&
                            emailAddressInvalid
                          ) {
                            setEmailAddressInvalid(false);
                          }
                          setEmailAddress(e.target.value);
                        }}
                      />
                      <FormErrorMessage>
                        A valid email address is required
                      </FormErrorMessage>
                    </ChakraFormControl>

                    <ChakraFormControl isInvalid={passwordInvalid}>
                      <FormLabel>Password</FormLabel>
                      <InputGroup size="lg">
                        <Input
                          pr="4.5rem"
                          type={show ? "text" : "password"}
                          id="password"
                          value={password}
                          placeholder="Password"
                          onChange={(e) => {
                            if (e.target.value.length >= 8 && passwordInvalid) {
                              setPasswordInvalid(false);
                            }
                            setPassword(e.target.value);
                          }}
                        />
                        <InputRightElement width="4.5rem">
                          <Button h="1.75rem" size="sm" onClick={handleClick}>
                            {show ? "Hide" : "Show"}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      {!passwordInvalid && (
                        <FormHelperText>Minimum 8 characters</FormHelperText>
                      )}
                      <FormErrorMessage>
                        Your password must be a minimum 8 characters
                      </FormErrorMessage>
                    </ChakraFormControl>

                    <Button
                      rightIcon={<ArrowForwardIcon />}
                      tw="tw-mt-4 tw-w-full"
                      colorScheme="red"
                      onClick={() => {
                        nextStep();
                      }}
                    >
                      NEXT
                    </Button>
                  </>
                )}
                {step === 2 && (
                  <>
                    <ChakraFormControl isInvalid={parentFirstNameInvalid}>
                      <FormLabel>Parent/Guardian First Name</FormLabel>
                      <Input
                        size="lg"
                        value={parentFirstName}
                        type="name"
                        placeholder="First Name"
                        onChange={(e) => {
                          setParentFirstNameInvalid(e.target.value === "");
                          setParentFirstName(e.target.value);
                        }}
                      />
                      <FormErrorMessage>
                        A parent or guardian's first name is required
                      </FormErrorMessage>
                    </ChakraFormControl>

                    <ChakraFormControl isInvalid={parentFamilyNameInvalid}>
                      <FormLabel>Parent/Guardian Surname</FormLabel>
                      <Input
                        size="lg"
                        type="name"
                        value={parentFamilyName}
                        placeholder="Surname"
                        onChange={(e) => {
                          setParentFamilyNameInvalid(e.target.value === "");
                          setParentFamilyName(e.target.value);
                        }}
                      />
                      <FormErrorMessage>
                        A parent or guardian's surname is required
                      </FormErrorMessage>
                    </ChakraFormControl>
                    <ChakraFormControl isInvalid={parentEmailAddressInvalid}>
                      <FormLabel>Parent/Guardian Email Address</FormLabel>
                      <Input
                        size="lg"
                        value={parentEmailAddress}
                        type="name"
                        placeholder="Email Address"
                        onChange={(e) => {
                          if (
                            e.target.value.includes("@") &&
                            e.target.value.split("@")[1].includes(".") &&
                            e.target.value.split("@")[1].split(".")[1].length >
                              1 &&
                            parentEmailAddressInvalid
                          ) {
                            setParentEmailAddressInvalid(false);
                          }
                          setParentEmailAddress(e.target.value);
                        }}
                      />
                      <FormErrorMessage>
                        A parent or guardian's email address is required
                      </FormErrorMessage>
                      {!parentEmailAddressInvalid && (
                        <FormHelperText>
                          Club communications will only be sent to this email
                          address. Please ensure it is correct.
                        </FormHelperText>
                      )}
                    </ChakraFormControl>
                    <ChakraFormControl isInvalid={parentMobileNumberInvalid}>
                      <FormLabel>Parent/Guardian Mobile Number</FormLabel>
                      <Input
                        size="lg"
                        type="number"
                        value={parentMobileNumber}
                        placeholder="Mobile Number"
                        onChange={(e) => {
                          if (
                            e.target.value.length === 11 &&
                            !e.target.value.includes(".") &&
                            parentMobileNumberInvalid
                          ) {
                            setParentMobileNumberInvalid(false);
                          }
                          setParentMobileNumber(e.target.value);
                        }}
                      />
                      <FormErrorMessage>
                        A parent or guardian's mobile number is required
                      </FormErrorMessage>
                    </ChakraFormControl>

                    <Button
                      rightIcon={<ArrowForwardIcon />}
                      tw="tw-mt-4 tw-w-full"
                      colorScheme="red"
                      onClick={() => {
                        nextStep();
                      }}
                    >
                      NEXT
                    </Button>
                    <Button
                      tw="tw-mt-2 tw-w-full"
                      variant="outline"
                      colorScheme="red"
                      onClick={() => {
                        previousStep();
                      }}
                    >
                      BACK
                    </Button>
                  </>
                )}
                {step === 3 && (
                  <>
                    <ChakraFormControl isInvalid={genderInvalid}>
                      <FormLabel>Gender</FormLabel>
                      <InputGroup size="lg">
                        <Select
                          id="gender"
                          value={gender}
                          placeholder="Select option"
                          onChange={(e) => {
                            setGenderInvalid(false);
                            setGender(e.target.value);
                          }}
                        >
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">
                            Dont identify as male / female
                          </option>
                        </Select>
                      </InputGroup>
                      {!genderInvalid && (
                        <FormHelperText>
                          We collect this personal data to ensure our club is
                          attracting everyone, no matter your age or gender.
                        </FormHelperText>
                      )}
                      <FormErrorMessage>
                        Please select an option.
                      </FormErrorMessage>
                    </ChakraFormControl>

                    <ChakraFormControl isInvalid={phoneNumberInvalid}>
                      <FormLabel>Mobile Number</FormLabel>
                      <Input
                        size="lg"
                        id="mobileNumber"
                        autoComplete="phone"
                        value={phoneNumber}
                        type="number"
                        placeholder="Mobile Number"
                        onChange={(e) => {
                          if (
                            e.target.value.length === 11 &&
                            !e.target.value.includes(".") &&
                            phoneNumberInvalid
                          ) {
                            setPhoneNumberInvalid(false);
                          }
                          setPhoneNumber(e.target.value);
                        }}
                      />
                      <FormErrorMessage>
                        A valid phone number is required
                      </FormErrorMessage>
                      {!phoneNumberInvalid && (
                        <FormHelperText>
                          We collect your mobile number incase you need to be
                          contacted by a coach in regards to a session. This is
                          also used for 2FA if you're an admin to protect
                          members data.
                        </FormHelperText>
                      )}
                    </ChakraFormControl>

                    <ChakraFormControl isInvalid={postCodeInvalid}>
                      <FormLabel>Postcode</FormLabel>
                      <AddressLookUp
                        value={postCode}
                        setLat={setLat}
                        setLong={setLong}
                        setPostCode={setPostCode}
                        setPostCodeInvalid={setPostCodeInvalid}
                      />

                      {!postCodeInvalid && (
                        <>
                          <FormHelperText>
                            We collect your postcode to ensure our club is
                            providing sessions in the best locations for its
                            members.
                          </FormHelperText>
                        </>
                      )}
                      <FormErrorMessage>
                        A valid postcode is required
                      </FormErrorMessage>
                    </ChakraFormControl>

                    <ChakraFormControl isInvalid={agreementInvalid}>
                      <FormLabel></FormLabel>
                      <Checkbox
                        size={"sm"}
                        onChange={(e) => {
                          console.log(e.target.checked);
                          setAgreementInvalid(!e.target.checked);
                          setAgreement(e.target.checked);
                        }}
                      >
                        I have read and agree to the club constitution, policies
                        and code of conduct&nbsp;
                        <Link
                          target="_blank"
                          href="/documents"
                          textDecoration={"underline"}
                        >
                          found here
                        </Link>
                        .
                      </Checkbox>
                      <FormErrorMessage>
                        You must read and agree to the club policies and code of
                        conduct.
                      </FormErrorMessage>
                    </ChakraFormControl>

                    <Button
                      isDisabled={!postCode || !agreement || !lat || !long}
                      isLoading={buttonDisabled}
                      loadingText="Please Wait"
                      tw="tw-w-full tw-mt-4"
                      colorScheme="red"
                      onClick={() => {
                        signup();
                      }}
                    >
                      SIGN UP
                    </Button>
                    <Button
                      tw="tw-mt-2 tw-w-full"
                      variant="outline"
                      colorScheme="red"
                      onClick={() => {
                        previousStep();
                      }}
                    >
                      BACK
                    </Button>
                  </>
                )}
                <HaveAnAccountButton>
                  Already have an account?{" "}
                  <HaveAnAccountText href={signInUrl}>
                    Sign In
                  </HaveAnAccountText>
                </HaveAnAccountButton>
              </Form>
            </FormContainer>
          </MainContent>
        </MainContainer>
        <IllustrationContainer>
          <IllustrationImage imageSrc={illustrationImageSrc} />
        </IllustrationContainer>
      </Content>
    </Container>
  );
};
