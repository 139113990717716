import React from "react";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-8.svg";

const ContentWithPadding = tw(ContentWithPaddingXl)`tw-pb-0`;
const HeadingContainer = tw.div`tw-text-center`;
const Subheading = tw(SubheadingBase)`tw-mb-4`;
const Heading = tw(SectionHeading)``;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`tw-pointer-events-none -tw-z-20 tw-absolute tw-left-0 tw-bottom-0 tw-h-64 tw-w-64 tw-opacity-15 tw-transform -tw-translate-x-2/3 tw-text-primary-500`}
`;
const TwoColumn = tw.div`tw-mb-10 md:tw-ml-10 md:tw-mr-10 tw-mt-12`;
const Description = tw.blockquote`tw-text-left tw-text-lg tw-mt-5`;
const List = tw.li`tw-text-lg`;

export default ({
  subheading = "The important stuff",
  heading = "Eligibility, catagories and prizes",
  description = "",
}) => {
  return (
    <Container tw="tw-mb-32">
      <ContentWithPadding>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <TwoColumn>
          <SectionHeading tw="tw-text-lg tw-text-left">
            Eligibility
          </SectionHeading>
          <Description>
            <List>
              All adult members of Badtri will be included in the championships
            </List>
            <List>You must race under your own name and number entry</List>

            <List>You must race under the Badtri club name</List>
            <List>
              Points can't be transferred between entrants (one person giving
              another their points)
            </List>
            <List>You don’t have to race in club kit, but it’s encouraged</List>
            <List>
              Points are available for marshalling - in case you don’t want to,
              or can’t (e.g. injury) enter a particular race. That’s still
              participation so you shouldn’t lose out. You could effectively win
              the club championships by marshalling alone!
            </List>
            <List>
              You won’t get any points for a particular event, if you’re DQ’d
            </List>
            <List>
              Any inappropriate behaviour or trying to get an unfair advantage
              is against the spirt of the championship and will be looked at,
              and responded to, in the fairest possible way
            </List>
          </Description>
          <SectionHeading tw="tw-text-lg tw-text-left tw-mt-5">
            Categories
          </SectionHeading>
          <Description>
            There are two categories - Aspiring and Inspiring:
            <br />
            <br />
            <List>
              The Aspiring category is for those who have completed 5 or fewer
              triathlons when entering the championship - honesty please! There
              will be Gold/Silver/Bronze awards in this category
            </List>
            <List>
              The Inspiring category is 6 plus completed triathlons already
              under your belt, with Gold/Silver/Bronze awards in this category
            </List>
          </Description>
          <SectionHeading tw="tw-text-lg tw-text-left tw-mt-5">
            Prizes
          </SectionHeading>
          <Description>
            Gold, Silver, Bronze awards for both Aspiring and Inspiring
            categories:
            <br />
            <br />
            <List>Gold - £25 towards Spond session bookings</List>
            <List>Silver - £15 towards Spond session bookings</List>
            <List>Bronze - £10 towards Spond session bookings</List>
          </Description>
        </TwoColumn>
      </ContentWithPadding>
      <DecoratorBlob2 />
    </Container>
  );
};
